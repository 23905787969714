import axios from '@/lib/utils/_init/axios'
import '@/lib/utils/_init/appendPrototype'
import '@/lib/utils/_init/anime'
import '@/lib/utils/_init/mobx'

function init(apiUrl: string): void {
  axios(apiUrl)
}

export default init
