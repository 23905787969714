const parts = ['container', 'item', 'link', 'separator']

function variantRegular(props: Dict) {
  const { isCurrentPage } = props

  return {
    item: {
      '& > span': {
        cursor: 'unset',
      },
      '& > span:hover': {
        textDecoration: 'none',
      },
    },
  }
}

const variants = {
  regular: variantRegular,
}

const baseStyleLink = {
  transition: 'all 0.15s ease-out',
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'inherit',
  fontWeight: 'medium',
  _hover: {
    textDecoration: 'underline',
  },
  _focus: {
    boxShadow: 'none',
  },
}

const baseStyle = {
  link: baseStyleLink,
}

const defaultProps = {
  variant: 'regular',
}

export default {
  parts,
  baseStyle,
  variants,
  defaultProps,
}
