/* eslint-disable @typescript-eslint/no-explicit-any */
export function getMatchingRoute(
  pathname: string,
  routes: Record<string, any>,
): any {
  for (const route of Object.values(routes)) {
    if (typeof route !== 'object' || route == null) {
      continue
    }

    if ((route as any).href === pathname) {
      return route
    } else {
      const result = getMatchingRoute(pathname, route)
      if (result && result.href) return result
    }
  }
}
