const VARIABLE_NAME = {
  pageView: {
    page: 'customPage',
  },
  event: {
    category: 'category',
    action: 'action',
    label: 'label',
    value: 'value',
  },
} as const

type LogEventParams = {
  category: string
  action: string
  label?: string
  value?: string
}

type LogPageviewParams = {
  customPage: string
}
export type GaEcommerce = null | {
  currency?: string
  value?: number
  coupon?: string
  payment_type?: string
  items?: {
    item_id?: string
    item_name?: string
    affiliation?: string
    coupon?: string
    currency?: string
    discount?: number
    index?: number
    item_brand?: string
    item_category?: string
    item_category2?: string
    item_category3?: string
    item_category4?: string
    item_category5?: string
    item_list_id?: string
    item_list_name?: string
    item_variant?: string
    location_id?: string
    price?: number
    quantity?: number
  }[]
}

type EcommerceParams = {
  ecommerce_type: string
  ecommerce: null | GaEcommerce
}

type GTMParams = {
  event: string
  user_id?: number | string
} & (
  | (Partial<{ ga: LogEventParams }> & Partial<LogPageviewParams>)
  | EcommerceParams
)

function isDataInvalid(data: GTMParams): boolean {
  return typeof data.event !== 'string'
}

export function gtmPush(data: GTMParams): void {
  if (!window || !window.dataLayer) return

  if (!isDataInvalid(data)) {
    window.dataLayer.push(data)
  }
}

export function logPageview(customDimensions = {}): void {
  const { pageView } = VARIABLE_NAME

  const customData = {
    [pageView.page]: `${window.location.pathname}${window.location.search}`,
    ...customDimensions,
  }

  gtmPush({
    event: 'trackPageview',
    ...customData,
  })
}

export function logEvent({
  category,
  action,
  label,
  value,
}: LogEventParams): void {
  const { event } = VARIABLE_NAME
  const ga = {
    [event.category]: category,
    [event.action]: action,
    [event.label]: label,
    [event.value]: value,
  }
  gtmPush({
    event: 'Track Custom Event',
    ga,
  })
}

export function logEcommerce(
  name: string,
  ecommerce: EcommerceParams['ecommerce'],
): void {
  gtmPush({
    event: 'ecommerce',
    ecommerce_type: name,
    ecommerce,
  })
}
