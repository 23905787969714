const KEY = 'token'

async function get(): Promise<string[]> {
  let value = localStorage.getItem(KEY)

  if (!value) {
    value = sessionStorage.getItem(KEY)
  }

  if (!value) {
    throw null
  }

  const values = value.split(/\:\:/)

  if (new Date(parseInt(values[3]) * 1000).getTime() < new Date().getTime()) {
    throw null
  }

  return values
}

async function set(
  accessToken: string,
  client: string,
  uid: string,
  expiry: string,
  persist = false,
): Promise<void> {
  if (persist) {
    sessionStorage.removeItem(KEY)
    localStorage.setItem(KEY, `${uid}::${accessToken}::${client}::${expiry}`)
  } else {
    localStorage.removeItem(KEY)
    sessionStorage.setItem(KEY, `${uid}::${accessToken}::${client}::${expiry}`)
  }
}

async function del(): Promise<void> {
  localStorage.removeItem(KEY)
  sessionStorage.removeItem(KEY)
}

export default {
  set,
  get,
  del,
}
