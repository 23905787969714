import { useRef, useCallback, RefObject } from 'react'

export interface UseFastClickCallbackResult {
  isMouseDown: RefObject<boolean>
  handleMouseDown: () => void
  handleMouseUp: () => void
}

export function useFastClickCallback(
  cb: () => void,
): UseFastClickCallbackResult {
  const isMouseDown = useRef<boolean>(false)

  const handleMouseDown = useCallback(() => {
    isMouseDown.current = true
  }, [])

  const handleMouseUp = useCallback(() => {
    if (isMouseDown.current) {
      isMouseDown.current = false
      cb()
    }
  }, [isMouseDown, cb])

  return {
    isMouseDown,
    handleMouseDown,
    handleMouseUp,
  }
}
