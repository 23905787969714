import init from '@/lib/utils/_init'

const circleBranch = process.env.CIRCLE_BRANCH

const envObj = {
  API_URL: process.env.API_URL || '',
  APP_URL: process.env.APP_URL || 'http://localhost:8081',

  STRIPE_PKEY: process.env.STRIPE_PKEY_TEST,

  GTM_CONTAINER_ID: '',

  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL || '',
  CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID:
    process.env.STAGING_CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID || '',
  CONSENT_WOW_SIGN_UP_API_KEY:
    process.env.STAGING_CONSENT_WOW_SIGN_UP_API_KEY || '',
  CONSENT_WOW_PRIVACY_SETTING_API_KEY:
    process.env.STAGING_CONSENT_WOW_PRIVACY_SETTING_API_KEY || '',

  IS_DEV: process.env.NODE_ENV === 'development',
}

if (circleBranch === 'production') {
  envObj.GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID || ''
  envObj.APP_URL = process.env.PRODUCTION_APP_URL || ''
  envObj.API_URL = process.env.PRODUCTION_API_URL || ''

  envObj.STRIPE_PKEY = process.env.STRIPE_PKEY

  envObj.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID =
    process.env.PRODUCTION_CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID || ''
  envObj.CONSENT_WOW_SIGN_UP_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_SIGN_UP_API_KEY || ''
  envObj.CONSENT_WOW_PRIVACY_SETTING_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_PRIVACY_SETTING_API_KEY || ''
} else if (circleBranch === 'main') {
  envObj.APP_URL = process.env.STAGING_APP_URL || ''
  envObj.API_URL = process.env.STAGING_API_URL || ''
}

if (!envObj.API_URL) {
  console.warn('API_URL environment variable must have value')
}

init(envObj.API_URL)

export default { ...envObj }
