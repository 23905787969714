import { createContext } from 'react'
import { makeObservable, observable, action } from 'mobx'

import { Profile, Account } from '@/features/account/types'
import { gtmPush, logEcommerce } from '@/lib/gtm'

export class State {
  static initialState = {
    company_name: null,
    email: '',
    id: -1,
    need_change_password: false,
    phone_number: '',
    profile: null,
    uid: '',
  }

  company_name?: string | null
  email = ''
  id = -1
  need_change_password = false
  phone_number = ''
  profile?: Profile | null
  uid = ''

  constructor() {
    this.init = this.init.bind(this)
    this.setAccount = this.setAccount.bind(this)
    this.clearAccount = this.clearAccount.bind(this)

    this.init()

    makeObservable(this, {
      clearAccount: action,
      company_name: observable,
      email: observable,
      id: observable,
      need_change_password: observable,
      phone_number: observable,
      profile: observable,
      setAccount: action,
      uid: observable,
    })
  }

  private init() {
    this.company_name = State.initialState.company_name
    this.email = State.initialState.email
    this.id = State.initialState.id
    this.need_change_password = State.initialState.need_change_password
    this.phone_number = State.initialState.phone_number
    this.profile = State.initialState.profile
    this.uid = State.initialState.uid
  }

  setAccount(account: Account): void {
    this.company_name = account.company_name
    this.email = account.email
    this.id = account.id
    this.need_change_password = account.need_change_password
    this.phone_number = account.phone_number
    this.uid = account.uid
    this.onChangeUserId(this.id)
  }

  setProfile(profile: Profile): void {
    this.profile = {
      ...this.profile,
      ...profile,
    }
  }

  clearAccount(): void {
    this.onChangeUserId('')
    this.init()
  }

  onChangeUserId(id: number | string): void {
    gtmPush({
      event: 'userData',
      user_id: id,
    })
    if (id === '') {
      logEcommerce('', null)
    }
  }
}

export const defaultState = new State()
const accountContext = createContext<State>(defaultState)

export default accountContext
