import { useEffect, useState, useContext, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { getMatchingRoute } from '@/lib/utils/route'
import * as Routes from '@/routes'

import LoadingContext from '@/contexts/loading'
import AccountContext from '@/contexts/account'

import authApi from '@/features/auth/api/v1'
import accountApi from '@/features/account/api/v1'

interface UseProtectRouteResult {
  currentRoute: Route
  isChecked: boolean
}

export function useHooks(_?: string): UseProtectRouteResult {
  const router = useRouter()
  const currentRoute = getMatchingRoute(router.pathname, Routes)

  const [isChecked, setIsChecked] = useState(false)

  const loadingContext = useContext(LoadingContext)
  const accountContext = useContext(AccountContext)

  const queryClient = useQueryClient()

  const setChecked = useCallback(() => {
    if (!isChecked) {
      setIsChecked(true)
    }
  }, [isChecked])

  const handleUnauthorizedError = (_error: unknown) => {
    const error = _error as AxiosError

    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          accountContext.clearAccount()
          if (currentRoute && currentRoute.isProtected) {
            router.replace(Routes.signInRoute.href).then(setChecked)
          }
          break

        default:
          // code...
          break
      }
    }
  }

  const retryUnauthorizedError = useCallback(
    (failureCount: number, _error: unknown) => {
      const error = _error as AxiosError

      if (error.response) {
        switch (error.response.status) {
          case 401:
          case 403:
            return false
            break

          default:
            break
        }
        return false
      } else if (error.request) {
        return false
      }
      return failureCount < 3
    },
    [],
  )

  useEffect(() => {
    const defaultQueryOption = queryClient.getDefaultOptions()
    queryClient.setDefaultOptions({
      queries: {
        ...defaultQueryOption.queries,
        retry: retryUnauthorizedError,
        onError: handleUnauthorizedError,
      },
      mutations: {
        ...defaultQueryOption.mutations,
        onError: handleUnauthorizedError,
      },
    })
  }, [])

  const {
    mutate: validateToken,
    data,
    isSuccess,
    isError,
  } = useMutation(authApi.validateToken, { onError: handleUnauthorizedError })

  // if validate token success
  useEffect(() => {
    if (isSuccess && data) {
      accountContext.setAccount(data.data.data.attributes)

      const need_change_password =
        data.data.data.attributes.need_change_password

      if (
        // if on public route or not found page
        !currentRoute ||
        currentRoute.isProtected ||
        currentRoute.allowSignedUser
      ) {
        setIsChecked(true)
      } else if (
        need_change_password &&
        router.asPath !== Routes.passwordExpirationRoute.href
      ) {
        setIsChecked(true)
        router.replace(Routes.passwordExpirationRoute.href)
      } else if (
        !currentRoute.isProtected &&
        !currentRoute.allowSignedUser &&
        Boolean(accountContext.uid)
      ) {
        router.replace(Routes.dashBoardRoutes.href).then(setChecked)
      }
    }
  }, [isSuccess])

  // if validate token failed
  useEffect(() => {
    if (isError && (!currentRoute || !currentRoute.isProtected)) {
      setIsChecked(true)
    }
  }, [isError])

  // if on 404 page or isChecked is true -> hide loading screen
  useEffect(() => {
    if (isChecked || !currentRoute) {
      loadingContext.hide()
    }
  }, [isChecked])

  // on route change
  useEffect(() => {
    if (isChecked) {
      if (!currentRoute) {
        if (Boolean(accountContext.uid)) {
          router.replace(Routes.dashBoardRoutes.href)
        } else {
          router.replace(Routes.signInRoute.href)
        }
      } else if (
        accountContext.need_change_password &&
        router.asPath !== Routes.passwordExpirationRoute.href
      ) {
        router.replace(Routes.passwordExpirationRoute.href)
      } else if (currentRoute.isProtected && !Boolean(accountContext.uid)) {
        router.replace(Routes.signInRoute.href)
      } else if (
        !currentRoute.isProtected &&
        !currentRoute.allowSignedUser &&
        Boolean(accountContext.uid)
      ) {
        router.replace(Routes.dashBoardRoutes.href)
      }
    }
  }, [isChecked, currentRoute])

  // check token
  useEffect(() => {
    validateToken({})
  }, [])

  useQuery(
    accountApi.profile.getQueryKey(),
    () => accountApi.profile.queryFn({}),
    {
      onError: handleUnauthorizedError,
      onSuccess: (profile) => {
        if (profile) accountContext.setProfile(profile)
      },
      refetchOnWindowFocus: 'always',
      enabled: isChecked && Boolean(accountContext.uid),
    },
  )

  // get Profile if not yet
  useEffect(() => {
    queryClient.resetQueries()
  }, [accountContext.uid])

  return {
    isChecked,
    currentRoute,
  }
}
