import { ReactElement, CSSProperties, memo } from 'react'
import { Icon, HStack, Avatar, Text, Link, Box } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FiSettings, FiLogOut } from 'react-icons/fi'

import DropDownItem from '@/components/atoms/DropDownItem'
import DropDownContainer from '@/components/atoms/DropDownContainer'
import NavItem from '@/components/atoms/NavItem'

import { State as AccountState } from '@/contexts/account'
import { NAV_BAR_HEIGHT } from '@/styles/styles/layerStyles'
import { ReactComponent as Logo } from '@/svg/logo.svg'
import { dashBoardRoutes, orientationRoute } from '@/routes'

import { useMenu } from './methods'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
  account: AccountState
  onClickSetting: () => void
  onClickSignout: () => void
}

function NavBar({
  id,
  className,
  style,
  account,
  onClickSignout,
  onClickSetting,
}: Props): ReactElement {
  const { isOpen, onBlur, onMouseUp, onMouseDown } = useMenu()

  return (
    <Box
      sx={{
        bg: 'white',
        w: '100%',
        boxShadow: 'inset 0px -1px 0px #F6EEE4',
        zIndex: 'docked',
      }}
    >
      <HStack
        id={id}
        className={className}
        style={style}
        as="nav"
        layerStyle="navBar"
        spacing="0"
        sx={{
          fontSize: 'sm',
          color: 'gray.500',
          justifyContent: 'space-between',
          boxShadow: 'inherit',
        }}
      >
        <HStack spacing="4" sx={{ alignItems: 'stretch' }}>
          <NextLink href={dashBoardRoutes.href} passHref>
            <Link>
              <Icon h={NAV_BAR_HEIGHT} as={Logo} w="auto" />
            </Link>
          </NextLink>
          <HStack spacing="0" sx={{ alignItems: 'stretch' }}>
            <NavItem label="ชุดเอกสาร" href={dashBoardRoutes.href} />
            <NavItem label="สอนใช้งานชุดเอกสาร" href={orientationRoute.href} />
          </HStack>
        </HStack>
        <Box position="relative">
          <HStack
            layerStyle="actionable"
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            spacing="2"
          >
            <Text fontWeight="medium" color="gray.750">
              {account.email}
            </Text>
            <Avatar
              size="md"
              name={account.email}
              sx={{
                boxSize: '10',
                fontFamily: 'roboto',
                fontWeight: 'medium',
                bg: 'primary.400',
                color: 'white',
              }}
            />
          </HStack>
          <DropDownContainer
            state={isOpen}
            onBlur={onBlur}
            defaultTransitionY="14px"
            sx={{
              right: {
                base: 'unset',
                sm: 0,
              },
            }}
          >
            <DropDownItem
              symbolLeft={<Icon as={FiSettings} boxSize="4" />}
              onClick={() => {
                onBlur()
                onClickSetting()
              }}
              label="ตั้งค่า"
            />
            <DropDownItem
              symbolLeft={<Icon as={FiLogOut} boxSize="4" />}
              onClick={() => {
                onBlur()
                onClickSignout()
              }}
              label="ออกจากระบบ"
            />
          </DropDownContainer>
        </Box>
      </HStack>
    </Box>
  )
}

NavBar.propTypes = {}

NavBar.defaultProps = {
  className: '',
}

export default memo(NavBar)
