import { ReactElement, CSSProperties, memo } from 'react'
import { Stack, HStack, Text, Link, Box } from '@chakra-ui/react'

import { termsRoute, policyRoute } from '@/routes/external'
import { FOOTER_HEIGHT } from '@/styles/styles/layerStyles'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
}

function Footer({ id, className, style }: Props): ReactElement {
  return (
    <Box
      sx={{ bg: 'white', w: '100%', boxShadow: 'inset 0px 1px 0px #E8E8E8' }}
    >
      <Stack
        id={id}
        className={className}
        style={style}
        layerStyle="footer"
        direction="row"
        spacing="0"
        sx={{
          fontSize: 'sm',
          color: 'gray.500',
          justifyContent: 'space-between',
          boxShadow: 'inherit',
        }}
      >
        <HStack h={FOOTER_HEIGHT}>
          <Text align="center">
            ©2021{' '}
            <Text as="strong" fontWeight="bold">
              nDataThoth Limited
            </Text>{' '}
            All Rights Reserved.
          </Text>
        </HStack>
        <Stack
          spacing="6"
          direction="row"
          sx={{
            minH: FOOTER_HEIGHT,
          }}
        >
          <HStack spacing="6">
            <Link
              isExternal
              href={termsRoute.href}
              sx={{
                fontWeight: 'normal',
                color: 'gray.500',
              }}
            >
              Terms
            </Link>
            <Link
              isExternal
              href={policyRoute.href}
              sx={{
                fontWeight: 'normal',
                color: 'gray.500',
              }}
            >
              Privacy Policy
            </Link>
          </HStack>
          <HStack spacing="6">
            <Text>Made with ♥ in Bangkok</Text>
          </HStack>
        </Stack>
      </Stack>
    </Box>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {
  className: '',
}

export default memo(Footer)
