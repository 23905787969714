export const NAV_BAR_HEIGHT = '56px'
export const BANNER_HEIGHT = '48px'
export const FOOTER_HEIGHT = '56px'

export const CONTENT_PADDING = 12

const CONTENT_MAX_WIDTH = '1600px'

const layerStyles = {
  content: {
    px: CONTENT_PADDING,
    w: '100%',
    my: 9,
    alignItems: 'stretch',
    maxW: CONTENT_MAX_WIDTH,
    mx: 'auto',
    flex: '1 1 auto',
  },
  footer: {
    px: CONTENT_PADDING,
    w: '100%',
    '& div': {
      justifyContent: 'center',
    },
    bg: 'white',
    maxW: CONTENT_MAX_WIDTH,
    mx: 'auto',
  },
  navBar: {
    minH: NAV_BAR_HEIGHT,
    px: CONTENT_PADDING,
    w: '100%',
    bg: 'white',
    maxW: CONTENT_MAX_WIDTH,
    mx: 'auto',
  },
  banner: {
    minH: BANNER_HEIGHT,
    px: CONTENT_PADDING,
    py: 4,
    w: '100%',
    maxW: CONTENT_MAX_WIDTH,
    color: 'white',
  },
  page: {
    flex: '1 1 auto',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    bg: 'white',
    px: 6,
    py: 10,
    borderRadius: 'lg',
  },
  paginationButton: {
    minWidth: '40px',
    h: '40px',
    p: '8px',
    color: 'primary.700',
    borderRadius: 'md',
  },
  actionable: {
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 150ms',
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 0.65,
    },
    _active: {
      opacity: 0.5,
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  pageContainer: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    p: '30px 16px',
    overflowX: 'hidden',
    maxWidth: '100%',
  },
  inputRightElement: {
    height: '100%',
    mr: '12px',
    p: '0px',
    color: 'gray.510',
  },
  contentBoxWithNavBar: {
    width: '100%',
    padding: '0px',
    bg: 'gray.25',
    flex: 1,
    display: 'flex',
  },
  contentWoNavBar: {
    width: '100%',
    minHeight: '100%',
    margin: '0px',
    padding: '0px',
    bg: 'gray.25',
    display: 'flex',
  },
  modalBackdrop: {
    background: 'rgba(63, 80, 92, 0.75)',
    backdropFilter: 'blur(4px)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    p: '64px',
    zIndex: '10',
  },
  errorIcon: {
    p: '0',
    m: '0',
    width: '100%',
    maxWidth: '50vmin',
    height: 'auto',
    transition: 'none',
  },
  auth: {
    page: {
      bg: 'primary.400',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& > *': {
        borderRadius: { base: '0', sm: 'lg' },
        height: {
          base: '100%',
          sm: 'fit-content',
        },
      },
    },
    container: {
      maxWidth: {
        base: '100%',
        sm: '620px',
      },
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    content: {
      maxWidth: '460px',
      width: '100%',
      mx: 'auto',
      transform: {
        base: 'scale(0.9)',
        sm: 'scale(1)',
      },
    },
    termAndPolicyContainer: {
      maxWidth: '460px',
      width: '100%',
      mt: '10',
      mb: '10',
      mx: 'auto',
      alignSelf: 'stretch',
      transform: {
        base: 'scale(0.9)',
        sm: 'scale(1)',
      },
    },
    image: {
      boxShadow: '3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
    },
  },
  dashboard: {
    item: {
      p: 4,
    },
  },
  payment: {
    summaryContainer: {
      flex: '0',
      minW: '440px',
      maxW: '440px',
      alignItems: 'stretch',
    },
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default layerStyles as any
