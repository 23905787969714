import { useState, useCallback } from 'react'
import { useSpring, AnimatedValue } from 'react-spring'
import { useTheme } from '@chakra-ui/react'

import { DEFAULT_SPRING_CONFIG } from '@/common/constants/spring'

interface SpringValues {
  boxShadow: string
}

interface UseAnimationsResult {
  isHover: boolean
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  spring: AnimatedValue<SpringValues>
}

export function useAnimations(): UseAnimationsResult {
  const theme = useTheme()

  const [isHover, setHover] = useState(false)

  const handleMouseEnter = useCallback(() => setHover(true), [])
  const handleMouseLeave = useCallback(() => setHover(false), [])

  const spring = useSpring({
    to: {
      boxShadow: `0px ${isHover ? '-6px' : '0px'} inset ${
        theme.colors.secondary[400]
      }`,
    },
    config: {
      ...DEFAULT_SPRING_CONFIG,
      clamp: !isHover,
    },
  }) as AnimatedValue<SpringValues>

  return {
    isHover,
    handleMouseEnter,
    handleMouseLeave,
    spring,
  }
}
