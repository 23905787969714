import { ReactElement, CSSProperties, memo, ReactNode } from 'react'
import { Collapse, HStack, CSSObject, Box } from '@chakra-ui/react'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
  sx?: CSSObject
  isOpen?: boolean
  children?: ReactNode
}

function Banner({
  id,
  className,
  style,
  children,
  sx,
  isOpen,
}: Props): ReactElement {
  return (
    <Box sx={{ bg: 'white', w: '100%' }}>
      <HStack
        id={id}
        className={className}
        style={style}
        sx={{
          bg: 'primary.400',
          w: '100%',
          justifyContent: 'center',
          ...sx,
        }}
      >
        <Collapse in={isOpen}>
          <HStack layerStyle="banner">{children}</HStack>
        </Collapse>
      </HStack>
    </Box>
  )
}

Banner.propTypes = {}

Banner.defaultProps = {
  isOpen: false,
  className: '',
}

export default memo(Banner)
