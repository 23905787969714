import axios from 'axios'

import envObj from '@/lib/env'

import { ConsentData, ConsentRecord } from './types'
import { getConsents } from './getConsent'
import { acceptConsent } from './acceptConsent'

interface UpdateConsentProps {
  consentStatus: boolean
  apiKey: string
  purposeId: string
  data: ConsentData
}

interface ResponseJSON {
  data: ConsentRecord
}

export async function updateConsent({
  consentStatus,
  apiKey,
  purposeId,
  data,
}: UpdateConsentProps): Promise<ResponseJSON | undefined> {
  const { data: consentRecords } = await getConsents({ apiKey })

  const record = await (async () => {
    const rec = consentRecords.find(
      (rec: ConsentRecord) => rec.attributes.email === data.email,
    )

    if (rec) {
      return rec.attributes
    } else if (consentStatus) {
      const response = await acceptConsent({
        apiKey: apiKey,
        purposeId: purposeId,
        data: data,
      })
      return response.data?.[0]?.attributes || null
    }
    return null
  })()

  if (record) {
    const res = await axios.put(
      `${envObj.CONSENT_WOW_API_URL}/${record.record_id}`,
      {
        status: consentStatus ? 'accepted' : 'withdrawn',
      },
      {
        headers: {
          Authorization: apiKey,
        },
      },
    )
    return res.data
  }
}
