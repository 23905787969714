import { AxiosResponse, AxiosRequestConfig } from 'axios'

import env from '@/lib/env'
import requests from '@/lib/requests'
import attachTokenHeader from '@/lib/requests/middlewares/attachTokenHeader'
import session from '@/lib/utils/session'
import { gtmPush, logEcommerce } from '@/lib/gtm'
import { acceptConsent } from '@/lib/utils/consent'

const baseUrl = '/api/v1/auth'

async function signIn({
  data,
  config = {},
  options = { persist: false },
}: {
  data: { email: string; password: string }
  config?: AxiosRequestConfig
  options?: { persist?: boolean }
}): Promise<AxiosResponse> {
  const res = await requests.post(`${baseUrl}/sign_in`, data, config)

  session.accessToken.set(
    res.headers['access-token'],
    res.headers['client'],
    res.headers['uid'],
    res.headers['expiry'],
    options.persist,
  )
  gtmPush({
    event: 'userData',
    user_id: res.data.user.id as string,
  })
  return res
}

async function signUp({
  data,
  config = {},
}: {
  data: {
    email: string
    password: string
    marketing_consent: boolean
    company_name: string
    phone_number: string
  }
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  const res: AxiosResponse = await requests.post(baseUrl, data, config)
  if (res.status === 200 && data.marketing_consent) {
    await acceptConsent({
      apiKey: env.CONSENT_WOW_SIGN_UP_API_KEY,
      purposeId: env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
      data: data,
    })
  }

  gtmPush({
    event: 'userData',
    user_id: res.data.id as string,
  })
  return res
}

async function confirmation({
  config,
}: {
  config: { params: { confirmation_token: string } } & AxiosRequestConfig
}): Promise<AxiosResponse> {
  return requests.get(`${baseUrl}/confirmation`, config)
}

async function resendConfirmation({
  data,
  config = {},
}: {
  data: { email: string }
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  return requests.post(`${baseUrl}/confirmation`, data, config)
}

async function signOut({
  config = {},
}: {
  config?: AxiosRequestConfig
}): Promise<AxiosResponse | void> {
  let res
  try {
    res = await requests.delete(
      `${baseUrl}/sign_out`,
      config,
      attachTokenHeader,
    )
    gtmPush({
      event: 'userData',
      user_id: '',
    })
    logEcommerce('', null)
  } catch (error) {}

  session.accessToken.del()
  return res
}

async function validateToken({
  config = {},
}: {
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  const res = await requests.get(
    `${baseUrl}/validate_token`,
    config,
    attachTokenHeader,
  )

  if (res.status > 401) {
    session.accessToken.del()
  }

  return res
}

async function forgotPassword({
  data,
  config = {},
}: {
  data: { email: string }
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  return requests.post(`${baseUrl}/password`, data, config)
}

async function setPassword({
  data,
  config = {},
}: {
  data: {
    password: string
    password_confirmation: string
    reset_password_token: string
  }
  config?: AxiosRequestConfig
}): Promise<AxiosResponse> {
  return requests.put(`${baseUrl}/password`, data, config)
}

export default {
  baseUrl,
  signIn,
  signUp,
  signOut,
  confirmation,
  resendConfirmation,
  forgotPassword,
  setPassword,
  validateToken,
}
