import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import components from './components'
import layerStyles from './styles/layerStyles'
import textStyles from './styles/textStyles'
import colors from './styles/colors'
import devices from './styles/size'
import style from './styles/style'

const theme = extendTheme({
  styles: {
    global: () => ({
      'html, body': {
        fontWeight: 'normal',
      },
    }),
  },
  colors,
  layerStyles,
  textStyles,
  components,
  devices,
  ...style,
  breakpoints: createBreakpoints(devices.breakPoints),
})

declare module '@emotion/react' {
  export interface Theme {
    colors: typeof theme.colors
    layerStyles: typeof theme.layerStyles
    textStyles: typeof theme.textStyles
    components: typeof theme.components
    devices: typeof theme.devices
  }
}

export default theme
