import Accordion from './accordion'
import Alert from './alert'
import Breadcrumb from './breadcrumb'
import Button from './button'
import Checkbox from './checkbox'
import Input from './input'
import Link from './link'
import List from './list'
import Skeleton from './skeleton'
import Table from './table'
import Divider from './divider'
// import Tooltip from './tooltip'
import Modal from './modal'

export default {
  Accordion,
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  Link,
  List,
  Skeleton,
  Table,
  Divider,
  // Tooltip,
  Modal,
}
