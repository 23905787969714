import { AxiosResponse, AxiosRequestConfig } from 'axios'

import env from '@/lib/env'
import requests from '@/lib/requests'
import attachTokenHeader from '@/lib/requests/middlewares/attachTokenHeader'

import { Profile, Account } from '@/features/account/types'
import { updateConsent } from '@/lib/utils/consent'

const baseUrl = '/api/v1'

const profile = {
  getQueryKey: (): unknown[] => [baseUrl, 'profile'],
  queryFn: async ({
    config = {},
  }: {
    config?: AxiosRequestConfig
  }): Promise<Profile | null> => {
    const res = await requests.get(`${baseUrl}/me`, config, attachTokenHeader)

    if (!res?.data?.user) return null

    return {
      hasVerified: Boolean(res.data.user.confirmed),
      hasPaid: Boolean(res.data.user.can_download_document),
      marketingConsent: Boolean(res.data.user.marketing_consent),
      reauthentication_required: Boolean(
        res.data.user.reauthentication_required,
      ),
      unconfirmed_email: res.data.user.unconfirmed_email,
      confirmation_sent_at: res.data.user.confirmation_sent_at,
      expire_password_after_days: res.data.user.expire_password_after_days,
      display_nps_survey: res.data.user.display_nps_survey,
    }
  },
}

const setPassword = async ({
  data,
  config = {},
}: {
  data: {
    current_password: string
    password: string
    password_confirmation: string
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res = await requests.put(
    `${baseUrl}/auth`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.user) return null

  return res.data.user
}

const setProfile = async ({
  data,
  config = {},
}: {
  data: {
    company_name: string | number | readonly string[] | undefined
    email: string | number | readonly string[] | undefined
    phone_number: string | number | readonly string[] | undefined
    marketing_consent: boolean
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res: AxiosResponse = await requests.put(
    `${baseUrl}/auth`,
    data,
    config,
    attachTokenHeader,
  )

  if (res.status === 200 && typeof data.email === 'string') {
    const consentData = { ...data, email: data.email }

    await updateConsent({
      consentStatus: data.marketing_consent,
      apiKey: env.CONSENT_WOW_PRIVACY_SETTING_API_KEY,
      purposeId: env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
      data: consentData,
    })
  }

  if (!res?.data?.data) return null

  return res.data.data
}

const verifyPassword = async ({
  data,
  config = {},
}: {
  data: {
    password: string
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res = await requests.post(
    `${baseUrl}/users/security`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.data) return null

  return res.data.data
}

const changeEmail = async ({
  data,
  config = {},
}: {
  data: {
    email: string
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res = await requests.put(
    `${baseUrl}/users/email`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.data) return null

  return res.data.data
}

const sendValidationEmail = async ({
  data,
  config = {},
}: {
  data: {
    email: string
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res = await requests.post(
    `${baseUrl}/auth/confirmation`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.data) return null

  return res.data.data
}

const cancelEmailChagne = async ({
  data,
  config = {},
}: {
  data: {
    email: string
  }
  config?: AxiosRequestConfig
}): Promise<Account | null> => {
  const res = await requests.put(
    `${baseUrl}/users/email/cancel`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.data) return null

  return res.data.data
}

const updatePasswordExpiration = async ({
  data,
  config = {},
}: {
  data: {
    expire_password_after_days: number | null
  }
  config?: AxiosRequestConfig
}): Promise<Profile | null> => {
  const res = await requests.put(
    `${baseUrl}/users/password_expiration`,
    data,
    config,
    attachTokenHeader,
  )

  if (!res?.data?.user) return null

  return res.data.user
}

export default {
  baseUrl,
  profile,
  setPassword,
  setProfile,
  verifyPassword,
  changeEmail,
  sendValidationEmail,
  cancelEmailChagne,
  updatePasswordExpiration,
}
