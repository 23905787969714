import axios from 'axios'
import envObj from '@/lib/env'
import { ConsentData, ConsentRecord } from './types'
interface AcceptConsentProps {
  apiKey: string
  purposeId: string
  data: ConsentData
}
interface ResponseJSON {
  data: ConsentRecord[]
}
export async function acceptConsent({
  apiKey,
  purposeId,
  data,
}: AcceptConsentProps): Promise<ResponseJSON> {
  const res = await axios.post(
    envObj.CONSENT_WOW_API_URL,
    {
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      phone_number: data.phone_number,
      consents: [
        {
          status: 'accepted',
          consent_purpose_id: purposeId,
        },
      ],
    },
    {
      headers: {
        Authorization: apiKey,
      },
    },
  )
  return res.data
}
