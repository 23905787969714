const textStyles = {
  auth: {
    titleText: {
      fontSize: '2xl',
      fontWeight: 'bold',
      color: 'gray.900',
    },
    subtitleText: {
      fontSize: 'sm',
      fontWeight: 'normal',
      color: 'gray.500',
    },
    paragraphText: {
      fontSize: '14px',
      fontWeight: 'normal',
      color: 'gray.900',
    },
    highLightParagraphText: {
      fontSize: '14px',
      fontWeight: 'medium',
      color: 'secondary.400',
    },
    errorText: {
      fontSize: '14px',
      color: 'status.error.color',
      fontWeight: '300',
      pt: '6px',
    },
  },
  error: {
    pageTitle: {
      fontSize: ['16px', '20px', '26px'],
      fontWeight: 'medium',
      color: 'primary.700',
    },
    pageSubtitle: {
      fontSize: ['12px', '14px', '16px'],
      fontWeight: 'medium',
      color: 'gray.400',
    },
  },
  inline: {
    display: 'inline-block',
  },
  breakall: {
    wordBreak: 'break-all',
  },
  icon: {
    md: {
      strokeWidth: '3px',
    },
  },
}

export default textStyles
