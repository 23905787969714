const colors = {
  primary: {
    50: '#EDF2F7',
    100: '#0069CC',
    200: '#004F99',
    300: '#003566',
    400: '#00254c',
    500: '#00254c',
    600: '#001337',
    700: '#000D32',
    800: '#000D32',
    900: '#000D32',
    1000: '#00042D',
  },
  secondary: {
    50: '#F2E6D9',
    100: '#E5CDB3',
    200: '#D8B48D',
    300: '#CB9B67',
    400: '#c59353',
    500: '#b8833d',
    600: '#986834',
    700: '#724F27',
    800: '#4C351A',
    900: '#261B0D',
  },
  tertiary: {
    50: '#FCECCF',
    100: '#F9D89F',
    200: '#F6C56F',
    300: '#F3B43F',
    400: '#ebb211',
    500: '#BE8F0E',
    600: '#8F690B',
    700: '#5F4407',
    800: '#473205',
    900: '#302103',
  },
  gray: {
    25: '#f8f8f8',
    50: '#F7FAFC',
    100: '#EDF2F7',
    150: '#e8e8e8',
    200: '#b2b2b2',
    300: '#cccccc',
    400: '#A0AEC0',
    420: '#A7AFB2',
    500: '#888888',
    600: '#4f4f4f',
    700: '#373D3F',
    750: '#2b2b2b',
    800: '#1A202C',
    900: '#171923',
  },
  status: {
    ok: {
      color: '#27AE60',
      bg: '#27AE60',
    },
    error: {
      color: '#ea5252',
      bg: '#C63636',
    },
    warning: {
      color: '#F2C94C',
      bg: '#F2C94C',
    },
  },
  switch: {
    50: '#27AE60',
    100: '#27AE60',
    200: '#27AE60',
    300: '#27AE60',
    400: '#27AE60',
    500: '#27AE60',
    600: '#27AE60',
    700: '#27AE60',
    800: '#27AE60',
    900: '#27AE60',
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default colors as any
