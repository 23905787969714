const style = {
  shadows: {
    md:
      '0px 4px 4px rgba(51, 51, 51, 0.04), 0px 14px 24px rgba(51, 51, 51, 0.12);',
  },
  radii: {
    sm: '4px',
    md: '6px',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 300,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    body: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
    heading: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
    mono: `Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
}

export default style
