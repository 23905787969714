import { createContext } from 'react'
import { makeObservable, observable, action } from 'mobx'

export class State {
  isShown = true

  constructor() {
    this.toggleShow = this.toggleShow.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    makeObservable(this, {
      isShown: observable,
      toggleShow: action,
      show: action,
      hide: action,
    })
  }

  toggleShow(): void {
    this.isShown = !this.isShown
  }

  show(): void {
    this.isShown = true
  }

  hide(): void {
    this.isShown = false
  }
}

export const defaultState = new State()
const modalState = createContext<State>(defaultState)

export default modalState
