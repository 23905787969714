import { useContext } from 'react'
import { useSpring, AnimatedValue } from 'react-spring'

import LoadingContext from '@/contexts/loading'

interface SpringValue {
  opacity: number
}

interface Hooks {
  containerSpring: AnimatedValue<SpringValue>
}

export function useHooks(): Hooks {
  const loadingState = useContext(LoadingContext)

  const containerSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: loadingState.isShown ? 1 : 0,
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
  }) as AnimatedValue<SpringValue>

  return {
    containerSpring,
  }
}
