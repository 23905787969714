import { ReactElement, memo, ReactNode, CSSProperties } from 'react'
import PropTypes from 'prop-types'
import { CSSObject } from '@chakra-ui/react'

import { AnimatedVStack } from '@/lib/springComponent'

import { useHooks } from './methods'

interface Props {
  children: ReactNode
  defaultTransitionX?: string
  defaultTransitionY?: string
  onBlur?: () => void
  state?: boolean
  sx?: CSSObject
  className?: string
  id?: string
  style?: CSSProperties
}

function DropDownContainer({
  id,
  state,
  sx,
  className,
  defaultTransitionX,
  defaultTransitionY,
  style,
  children,
  onBlur,
}: Props): ReactElement {
  const { spring, containerRef } = useHooks(
    state,
    defaultTransitionX,
    defaultTransitionY,
  )

  return (
    <AnimatedVStack
      id={id}
      className={className}
      style={{
        ...style,
        ...spring,
      }}
      ref={containerRef}
      onBlur={onBlur}
      tabIndex={-1}
      spacing="0px"
      sx={{
        fontWeight: 'light',
        w: '100%',
        minWidth: '180px',
        overflow: 'hidden',
        borderRadius: 'sm',
        boxShadow: 'md',
        position: 'absolute',
        zIndex: 'dropdown',
        ...sx,
      }}
    >
      {children}
    </AnimatedVStack>
  )
}

DropDownContainer.propTypes = {
  children: PropTypes.node.isRequired,
  defaultTransitionX: PropTypes.string,
  defaultTransitionY: PropTypes.string,
  state: PropTypes.bool,
  onBlur: PropTypes.func,
}

DropDownContainer.defaultProps = {
  state: false,
  defaultTransitionX: '0px',
  defaultTransitionY: '0px',
  className: '',
}

export default memo(DropDownContainer)
