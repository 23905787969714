import { useCallback } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useFastClickCallback } from '@/lib/hooks/fastClickCallback'

interface UseMenuResult {
  isOpen: boolean
  onBlur: () => void
  onMouseUp: () => void
  onMouseDown: () => void
  onToggle: () => void
}

export function useMenu(): UseMenuResult {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()

  const onBlur = useCallback(() => {
    if (!isMouseDown.current) onClose()
  }, [])

  const {
    isMouseDown,
    handleMouseUp: onMouseUp,
    handleMouseDown: onMouseDown,
  } = useFastClickCallback(isOpen ? onClose : onOpen)

  return {
    isOpen,
    onBlur,
    onMouseUp,
    onMouseDown,
    onToggle,
  }
}
