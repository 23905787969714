import { ReactElement, memo, ReactNode, CSSProperties } from 'react'
import PropTypes from 'prop-types'
import { HStack, Stack, Text } from '@chakra-ui/react'

interface Props {
  label: string
  onClick?: () => void
  isSelected?: boolean
  symbolLeft?: ReactNode
  symbolRight?: ReactNode
  className?: string
  id?: string
  style?: CSSProperties
}

function DropDownItem({
  id,
  className,
  style,
  label,
  symbolLeft,
  symbolRight,
  onClick,
  isSelected,
}: Props): ReactElement {
  return (
    <HStack
      id={id}
      className={className}
      style={style}
      spacing="2"
      onClick={onClick}
      sx={{
        transition: 'all 150ms',
        h: '10',
        w: '100%',
        px: '5',
        fontSize: 'sm',
        color: isSelected ? 'gray.500' : 'primary.400',
        bg: isSelected ? 'primary.500' : 'white',
        cursor: 'pointer',
        '&:hover': {
          color: 'white',
          bg: 'primary.400',
        },
      }}
    >
      {symbolLeft && <Stack w="6">{symbolLeft}</Stack>}
      <Text flex="1">{label}</Text>
      {symbolRight && <Stack w="6">{symbolRight}</Stack>}
    </HStack>
  )
}

DropDownItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  symbolLeft: PropTypes.node,
  symbolRight: PropTypes.node,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
}

DropDownItem.defaultProps = {
  isSelected: false,
  className: '',
}

export default memo(DropDownItem)
