import { ReactElement, CSSProperties, memo, useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Stack,
  HStack,
  Icon,
  Text,
  Link,
  useDisclosure,
  useToast,
  Button,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { IoMailUnreadOutline } from 'react-icons/io5'

import Banner from '@/components/organisms/Banner'
import accountContext from '@/contexts/account'
import { DEFAULT_TOAST_OPTIONS } from '@/common/constants/toast'

import authAPI from '@/features/auth/api/v1'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
}

function ConfirmationBanner({ id, className, style }: Props): ReactElement {
  const toast = useToast()
  const account = useContext(accountContext)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (!account.profile) return

    if (!account.profile.hasVerified) {
      onOpen()
    } else {
      onClose()
    }
  }, [account?.profile?.hasVerified])

  const { mutate, isLoading } = useMutation(authAPI.resendConfirmation, {
    onSuccess: () => {
      toast({
        ...DEFAULT_TOAST_OPTIONS,
        description: 'โปรดตรวจสอบอีเมลของคุณ',
        status: 'success',
      })
    },
  })

  return (
    <Banner id={id} className={className} style={style} isOpen={isOpen}>
      <Stack
        direction="row"
        spacing="10"
        fontWeight="medium"
        fontSize="sm"
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <HStack spacing="3">
          <Icon as={IoMailUnreadOutline} />
          <Text>
            กรุณาตรวจสอบ ‘กล่องจดหมายเข้า’ ในอีเมลของคุณ
            เพื่อเสร็จสิ้นการสร้างบัญชี
          </Text>
        </HStack>
        <Link as="div" color="tertiary.400">
          <Button
            isLoading={isLoading}
            onClick={() => mutate({ data: { email: account.email } })}
            variant="ghost"
            loadingText="กำลังดำเนินการส่งอีเมล"
            sx={{
              fontWeight: 'medium',
              fontSize: 'inherit',
              h: 'unset',
              color: 'inherit',
              p: 0,
              _hover: {},
            }}
          >
            ยืนยันอีเมลของคุณ
          </Button>
        </Link>
      </Stack>
    </Banner>
  )
}

ConfirmationBanner.propTypes = {}

ConfirmationBanner.defaultProps = {
  className: '',
}

export default memo(observer(ConfirmationBanner))
