import { ReactElement, memo } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'

import { useHooks } from './methods'

interface Props {
  children: (isChecked: boolean, currentRoute: Route) => ReactElement
}

function ProtectRoute({ children }: Props): ReactElement {
  const { isChecked, currentRoute } = useHooks()
  return children(isChecked, currentRoute)
}

ProtectRoute.propTypes = {
  children: PropTypes.func.isRequired,
}

ProtectRoute.defaultProps = {}

export default memo(observer(ProtectRoute))
