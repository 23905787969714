import { AxiosRequestConfig } from 'axios'

import session from '@/lib/utils/session'

import type { NextFunction, Response } from '@/lib/requests'

async function attachTokenHeader(
  config: AxiosRequestConfig,
  next: NextFunction,
): Promise<Response> {
  try {
    const accessToken = await session.accessToken.get()
    if (!config.headers) {
      config.headers = {}
    }

    config.headers['uid'] = `${accessToken[0]}`
    config.headers['access-token'] = `${accessToken[1]}`
    config.headers['client'] = `${accessToken[2]}`

    return next()
  } catch (error) {
    session.accessToken.del()
    throw { response: { status: 401, statusText: 'unauthorized' } }
  }
}

export default attachTokenHeader
