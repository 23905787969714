export const SITE_TITLE = 'PDPA Prokit'

export const signInRoute: Route = {
  title: `Sign In | ${SITE_TITLE}`,
  href: '/',
  allowSignedUser: false,
}

export const signUpRoute: Route = {
  title: `Sign Up | ${SITE_TITLE}`,
  href: '/sign-up',
  allowSignedUser: false,
}

export const forgotPasswordRoute: Route = {
  title: `Forgot Password? | ${SITE_TITLE}`,
  href: '/forgot-password',
  allowSignedUser: false,
}

export const setPasswordRoute: Route = {
  title: `Set Password | ${SITE_TITLE}`,
  href: '/set-password',
  allowSignedUser: false,
}

export const accountSettingsRoute: Route = {
  title: `Account Settings | ${SITE_TITLE}`,
  href: '/account-settings',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const resendConfirmAccountRoute: Route = {
  title: `Resend Confirmation | ${SITE_TITLE}`,
  href: '/resend-confirmation',
  allowSignedUser: false,
}

export const confirmAccountRoute: Route = {
  title: `Account Confirmation | ${SITE_TITLE}`,
  href: '/confirm-account',
  allowSignedUser: true,
}

export const passwordExpirationRoute: Route = {
  title: `Set Password | ${SITE_TITLE}`,
  href: '/password-expiration',
  allowSignedUser: true,
  isProtected: true,
}

export const dashBoardRoutes: Route = {
  title: `Dashboard | ${SITE_TITLE}`,
  href: '/dashboard',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const dashBoardRoute: Route = {
  title: `Dashboard | ${SITE_TITLE}`,
  href: '/dashboard/[id]',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const paymentBillingAddressRoute: Route = {
  title: `Payment | ${SITE_TITLE}`,
  href: '/payment',
  breadcrumbLabel: 'สรุปรายการสั่งซื้อ',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const paymentPaymentMethodRoute: Route = {
  title: `Payment | ${SITE_TITLE}`,
  href: '/payment/[token]',
  breadcrumbLabel: 'ช่องทางการชำระเงิน',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const paymentSuccessRoute: Route = {
  title: `Payment Success | ${SITE_TITLE}`,
  href: '/payment/[token]/success',
  breadcrumbLabel: 'เสร็จสิ้น',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const paymentInProgressRoute: Route = {
  title: `Processing | ${SITE_TITLE}`,
  href: '/payment/[token]/in-progress',
  breadcrumbLabel: 'กำลังดำเนินการ',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}

export const paymentRoutes: Route[] = [
  paymentBillingAddressRoute,
  paymentPaymentMethodRoute,
  paymentSuccessRoute,
]

export const orientationRoute: Route = {
  title: 'Orientation Video',
  href: '/orientation-video',
  hasNavBar: true,
  hasFooter: true,
  isProtected: true,
}
