import { ReactNode, createContext } from 'react'
import { makeObservable, observable, action } from 'mobx'

export class State {
  isShown = false
  child: ReactNode = null

  constructor() {
    this.toggleShow = this.toggleShow.bind(this)
    this.setChild = this.setChild.bind(this)
    this.clearChild = this.clearChild.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showModal = this.showModal.bind(this)

    makeObservable(this, {
      child: observable,
      isShown: observable,
      toggleShow: action,
      setChild: action,
      clearChild: action,
      hideModal: action,
      showModal: action,
    })
  }

  toggleShow(): void {
    this.isShown = !this.isShown
  }

  setChild(child: ReactNode): void {
    this.child = child
  }

  clearChild(): void {
    this.child = null
  }

  hideModal(): void {
    this.isShown = false
    this.child = null
  }

  showModal(child: ReactNode): void {
    this.isShown = true
    this.child = child
  }
}

export const defaultState = new State()
const modalState = createContext<State>(defaultState)

export default modalState
