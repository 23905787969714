import { ReactElement, CSSProperties, memo } from 'react'
import Link from 'next/link'
import { Text } from '@chakra-ui/react'

import { AnimatedVStack } from '@/lib/springComponent'

import { useAnimations } from './methods'

interface Props {
  href: string
  label: string
  className?: string
  id?: string
  style?: CSSProperties
}

function NavItem({ id, className, style, href, label }: Props): ReactElement {
  const { handleMouseEnter, handleMouseLeave, spring } = useAnimations()

  return (
    <Link href={href} passHref>
      <AnimatedVStack
        id={id}
        className={className}
        style={{
          ...style,
          ...spring,
        }}
        as="a"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        layerStyle="actionable"
        spacing="0"
        sx={{
          alignItems: 'stretch',
          minWidth: '140px',
          justifyContent: 'center',
          fontWeight: 'medium',
          fontSize: 'md',
          color: 'gray.750',
          px: '4',
        }}
      >
        <Text size="lg" align="center">
          {label}
        </Text>
      </AnimatedVStack>
    </Link>
  )
}

NavItem.propTypes = {}

NavItem.defaultProps = {
  className: '',
}

export default memo(NavItem)
