const baseStyle = {
  layerStyle: 'actionable',
  transition: `all 0.15s ease-out`,
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'primary.400',
  fontWeight: 'medium',
  _hover: {
    textDecoration: 'underline',
  },
  _focus: {
    boxShadow: 'none',
  },
}

const defaultProps = { layerStyle: 'actionable' }

export default {
  baseStyle,
  defaultProps,
}
