import {
  ReactElement,
  CSSProperties,
  memo,
  useContext,
  useCallback,
} from 'react'
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import authApi from '@/features/auth/api/v1'

import Navbar from '@/components/organisms/NavBar'

import accountContext from '@/contexts/account'
import { accountSettingsRoute, signInRoute } from '@/routes'
import { getErrorToastObject } from '@/lib/utils/formatter/errors'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
}

function NavBar({ id, className, style }: Props): ReactElement {
  const router = useRouter()
  const toast = useToast()

  const { mutateAsync } = useMutation(authApi.signOut, {
    onError: (error: AxiosError) => {
      toast(getErrorToastObject(error))
    },
  })

  const account = useContext(accountContext)

  const handleClickSetting = useCallback(() => {
    router.push(accountSettingsRoute.href)
  }, [])

  const handleClickSignout = useCallback(() => {
    mutateAsync({})
    router.replace(signInRoute.href)
    account.clearAccount()
  }, [])

  return (
    <Navbar
      id={id}
      className={className}
      style={style}
      account={account}
      onClickSetting={handleClickSetting}
      onClickSignout={handleClickSignout}
    />
  )
}

NavBar.propTypes = {}

NavBar.defaultProps = {
  className: '',
}

export default memo(observer(NavBar))
