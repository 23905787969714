import { VStack } from '@chakra-ui/react'
import BounceLoader from 'react-spinners/BounceLoader'
import { observer } from 'mobx-react-lite'

import { AnimatedVStack } from '@/lib/springComponent'

import { useHooks } from './methods'

function ComingSoon(): React.ReactElement {
  const { containerSpring } = useHooks()
  return (
    <AnimatedVStack
      style={{
        ...containerSpring,
        display: containerSpring.opacity.interpolate((value: number) =>
          value <= 0.005 ? 'none' : 'flex',
        ),
      }}
      layerStyle="modalBackdrop"
      sx={{
        bg: 'gray.1000',
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '5',
      }}
    >
      <VStack
        spacing="4"
        sx={{
          color: 'primary.400',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          m: 'auto',
        }}
      >
        <BounceLoader size="64px" color="currentColor" />
      </VStack>
    </AnimatedVStack>
  )
}

export default observer(ComingSoon)
