import { Fragment, ReactElement } from 'react'
import { Global, css } from '@emotion/react'

function GlobalStyle(): ReactElement {
  return (
    <Fragment>
      <Global
        // font
        styles={css`
          heading,
          body,
          input,
          textarea,
          table,
          th,
          td,
          button {
            font-family: Anakotmai, Kanit, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
          }

          @font-face {
            font-family: Anakotmai;
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-light.eot');
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-light.eot?#iefix')
                format('embedded-opentype'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-light.woff2')
                format('woff2'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-light.woff')
                format('woff'),
              url('/static/fonts/Anakotmai/desktop/ttf/Anakotmai-Light.ttf')
                format('truetype');
            font-style: normal;
            font-weight: 300;
            font-display: swap;
          }

          @font-face {
            font-family: Anakotmai;
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-medium.eot');
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-medium.eot?#iefix')
                format('embedded-opentype'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-medium.woff2')
                format('woff2'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-medium.woff')
                format('woff'),
              url('/static/fonts/Anakotmai/desktop/ttf/Anakotmai-Medium.ttf')
                format('truetype');
            font-style: normal;
            font-weight: 500;
            font-display: swap;
          }

          @font-face {
            font-family: Anakotmai;
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-bold.eot');
            src: url('/static/fonts/Anakotmai/webfont/anakotmai-bold.eot?#iefix')
                format('embedded-opentype'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-bold.woff2')
                format('woff2'),
              url('/static/fonts/Anakotmai/webfont/anakotmai-bold.woff')
                format('woff'),
              url('/static/fonts/Anakotmai/desktop/ttf/Anakotmai-Bold.ttf')
                format('truetype');
            font-style: normal;
            font-weight: 600;
            font-display: swap;
          }
        `}
      />
      <Global
        // selection
        styles={css`
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        `}
      />
      <Global
        // layout
        styles={css`
          * {
            box-sizing: border-box;
            outline: none;
            line-height: normal;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          *:focus {
          }

          #__next {
            min-height: 100vh;
            max-width: 100vw;
            display: flex;
          }

          html,
          body,
          #__next {
            min-width: 1280px;
            scroll-behavior: smooth;
          }
        `}
      />
    </Fragment>
  )
}

export default GlobalStyle
