import { FunctionComponent, Fragment, ReactNode } from 'react'
import { AppProps } from 'next/app'
import { NextComponentType } from 'next'
import { DefaultSeo, NextSeo } from 'next-seo'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'
import { Box, VStack } from '@chakra-ui/react'

import GlobalStyle from '@/styles/GlobalStyle'
import ThemeProvider from '@/styles/ThemeProvider'

import LoadingPage from '@/common/pages/Loading'
import NavBar from '@/common/templates/NavBar'
import Footer from '@/common/templates/Footer'
import ConfirmationBanner from '@/common/templates/ConfirmationBanner'

import ProtectRoute from '@/features/auth/components/atoms/ProtectRoute'

import AccountContext, {
  defaultState as accountState,
} from '@/contexts/account'
import ModalContext, { defaultState as modalState } from '@/contexts/modal'
import LoadingContext, {
  defaultState as loadingState,
} from '@/contexts/loading'
import queryClient from '@/contexts/query'

import env from '@/lib/env'

const Noop = ({ children }: { children: ReactNode }) => children

type PageComponentType = NextComponentType & {
  Layout?: FunctionComponent
}

function MyApp(props: AppProps): ReactNode {
  const { pageProps: ssrPageProps } = props
  const SsrComponent: PageComponentType = props.Component
  const Layout = (SsrComponent.Layout || Noop) as FunctionComponent

  return (
    <ThemeProvider>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AccountContext.Provider value={accountState}>
          <ModalContext.Provider value={modalState}>
            <LoadingContext.Provider value={loadingState}>
              <ProtectRoute>
                {(isChecked, currentRoute) => (
                  <Fragment>
                    <DefaultSeo
                      title="PDPA Prokit"
                      titleTemplate="%s"
                      description="ชุดเอกสารจำนวน 69 ชุด ที่ประกอบด้วย นโยบาย ขั้นตอน กระบวนการ แบบฟอร์ม ที่ธุรกิจต้องการ เพื่อให้ปฏิบัติตาม PDPA ได้อย่างถูกต้องครบถ้วน จบในที่เดียว"
                    />
                    {currentRoute && (
                      <NextSeo
                        title={currentRoute.title}
                        description={currentRoute.description}
                        canonical={`${env.APP_URL}`}
                        openGraph={{
                          url: `${env.APP_URL}`,
                          title: currentRoute.title,
                          description: currentRoute.description,
                          images: currentRoute.images || [
                            {
                              url: `${env.APP_URL}/static/images/og/two-businessman-investment-consultant-analyzing-company-financial-report.png`,
                              width: 800,
                              height: 600,
                              alt: currentRoute.title,
                            },
                          ],
                          site_name: currentRoute.title,
                        }}
                      />
                    )}
                    {isChecked && currentRoute && (
                      <Layout {...ssrPageProps}>
                        <VStack
                          spacing="0px"
                          sx={{ alignItems: 'stretch', w: '100%' }}
                        >
                          {currentRoute.hasNavBar && (
                            <VStack
                              spacing="0"
                              sx={{
                                alignItems: 'stretch',
                                zIndex: 'docked',
                                position: 'sticky',
                                top: '0',
                                left: '0',
                                right: '0',
                              }}
                            >
                              <ConfirmationBanner />
                              <NavBar />
                            </VStack>
                          )}
                          <Box
                            layerStyle={
                              currentRoute?.hasNavBar
                                ? 'contentBoxWithNavBar'
                                : 'contentWoNavBar'
                            }
                          >
                            <SsrComponent {...ssrPageProps} />
                          </Box>
                          {currentRoute.hasFooter && <Footer />}
                        </VStack>
                      </Layout>
                    )}
                    <LoadingPage />
                  </Fragment>
                )}
              </ProtectRoute>
            </LoadingContext.Provider>
          </ModalContext.Provider>
        </AccountContext.Provider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default MyApp
