import { useRef, RefObject } from 'react'
import { useSpring, AnimatedValue } from 'react-spring'

interface SpringValue {
  transform: string
  opacity: number
}

interface Hooks {
  spring: AnimatedValue<SpringValue>
  containerRef: RefObject<HTMLDivElement>
}

export function useHooks(
  state?: boolean,
  defaultTransitionX?: string,
  defaultTransitionY?: string,
): Hooks {
  const containerRef = useRef<HTMLDivElement>(null)

  const spring = useSpring({
    from: {
      display: 'none',
    },
    to: {
      display: 'flex',
      transform: state
        ? `translate3d(${defaultTransitionX}, calc(${defaultTransitionY} + 0px), 10px)`
        : `translate3d(${defaultTransitionX}, calc(${defaultTransitionY} + 8px), 10px)`,
      opacity: state ? 1 : 0,
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
    onStart: () => {
      if (state && containerRef.current) {
        containerRef.current.style.display = 'flex'
        if (state) containerRef.current.focus()
      }
    },
    onRest: () => {
      if (containerRef.current) {
        containerRef.current.style.display = state ? 'flex' : 'none'
      }
    },
  }) as AnimatedValue<SpringValue>

  return {
    spring,
    containerRef,
  }
}
