import facepaint from 'facepaint'

const breakpoints = {
  xs: '375px',
  sm: '768px',
  md: '1280px',
  lg: '1680px',
  xl: '1920px',
}

const devices = {
  breakPoints: breakpoints,
  mediaQueries: facepaint(
    Object.values(breakpoints).map((bp) => `@media (min-width: ${bp})`),
  ),
}

export default devices
