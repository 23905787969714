import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      staleTime: 1000 * 60 * 10,
    },
  },
})

export default queryClient
