import axios from 'axios'
import envObj from '@/lib/env'
import { ConsentRecord } from './types'

interface GetConsentProps {
  apiKey: string
}

interface ResponseJSON {
  data: ConsentRecord[]
}

export async function getConsents({
  apiKey,
}: GetConsentProps): Promise<ResponseJSON> {
  const res = await axios.get(envObj.CONSENT_WOW_API_URL, {
    headers: {
      Authorization: apiKey,
    },
  })
  return res.data
}
